
require.register("core-js/features/promise/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    module.exports = require('../../es/promise');

require('../../modules/esnext.aggregate-error');
require('../../modules/esnext.promise.all-settled');
require('../../modules/esnext.promise.try');
require('../../modules/esnext.promise.any');
  })();
});